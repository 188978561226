<template>
    <div class="userServiceList">
        <div class="ud-title" ref="myElement">
            <div class="ud-title-box">
                <div class="screen-left">
                    <commonSelect
                        @changeDate="changeDate"
                        @clickTagDate="clickTagDate"
                    ></commonSelect>
                </div>
                <div class="sr-item">
                    <div class="i-title">服务负责人</div>
                    <el-select v-model="serviceValue" @change="changeSelect">
                        <el-option
                        v-for="item in serviceOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="sr-item">
                    <div class="i-title">套餐类型</div>
                    <el-select v-model="userValue" @change="changeSelect">
                        <el-option
                        v-for="item in userOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="sr-item">
                    <div class="i-title">注册来源</div>
                    <el-select v-model="source" @change="changeSelect">
                        <el-option
                        v-for="item in sourceOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="sr-item">
                    <div class="i-title">培训反馈</div>
                    <el-select v-model="feedbackValue" @change="changeSelect">
                        <el-option
                        v-for="item in feedbackOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="sr-item">
                    <div class="i-title">编辑行业</div>
                    <el-select v-model="editValue" @change="changeSelect">
                        <el-option
                        v-for="item in editOptions"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="ud-title-screen flex-sbc">
                <div class="flex-wc">
                    <div class="c-search">
                        <div><i class="el-icon-search"></i></div>
                        <el-input v-model="keyword" placeholder="搜索商家名称或联系电话"></el-input>
                    </div>
                    <div class="c-btn" @click="clickSearch">搜索</div>
                </div>
                <div class="flex-sbc">
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        action
                        :limit="1"
                        :show-file-list="false"
                        :http-request="clickUpdataShop"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        >
                        <!-- <el-button  type="success">导入</el-button> -->
                        <div class="s-btn flex-cc">批量导入开通商家</div>
                    </el-upload>
                    <div class="t-t-btn flex-fec" v-if="selectGoods.length>0">
                        <div class="select">已选择{{selectGoods.length}}项</div>
                        <div class="cancel" @click="clickDeselect">取消选择</div>
                        <el-popover
                            placement="bottom-start"
                            trigger="hover"
                            :width="120"
                        >
                            <template #default>
                                <div class="batch-btn-box">
                                    <div class="batch" @click="isEditShow = true">批量编辑行业</div>
                                    <div class="batch" @click="clickBatchSupplier">批量编辑供应商</div>
                                </div>
                            </template>
                            <template #reference>
                                <el-button style="margin-left: 20px" type="primary"
                                    >
                                    批量处理
                                </el-button>
                            </template>
                        </el-popover>
                    </div>
                    <div v-else class="t-btn flex-cc">批量处理</div>
                </div>
            </div>
        </div>
        <div class="ss-content" v-loading="loading">
            <el-table class="table" :data="tableData" 
                ref="multipleTableRef"
                @selection-change="handleSelectionChange"
                style="width: 100%" :height="'calc(100vh - ' + height + ')'">
                 <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="name" label="套餐类型" width="100">
                    <template slot-scope="scope">
                        <div class="is-pro-wrap">
                            <div class="btn">
                                <div>{{scope.row.activate_package_name}}</div>
                                <div>小程序</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="商家名称" width="150"></el-table-column>
                <el-table-column prop="operator_name" label="经营者" width="100"></el-table-column>
                <el-table-column prop="phone" label="联系方式" width="120"></el-table-column>
                <el-table-column prop="address" label="所在地区" show-overflow-tooltip></el-table-column>
                <el-table-column prop="industry_name" label="行业类别"></el-table-column>
                <el-table-column show-overflow-tooltip prop="pro_first_start_time" label="开通/到期时间" width="180">
                    <template slot-scope="scope">
                        <div>
                            <div v-if="scope.row.pro_first_start_time">{{scope.row.pro_first_start_time}}</div>
                            <div v-else><i class="el-icon-minus"></i><i class="el-icon-minus"></i></div>
                            <div v-if="scope.row.pro_end_time">{{scope.row.pro_end_time}}</div>
                            <div v-else><i class="el-icon-minus"></i><i class="el-icon-minus"></i></div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column show-overflow-tooltip prop="pro_end_time" label=" 注册/最后操作时间" width="180">
                    <template slot-scope="scope">
                        <div>
                            <div v-if="scope.row.time">{{scope.row.time}}</div>
                            <div v-else><i class="el-icon-minus"></i><i class="el-icon-minus"></i></div>
                            <div v-if="scope.row.end_operate_time">{{scope.row.end_operate_time}}</div>
                            <div v-else><i class="el-icon-minus"></i><i class="el-icon-minus"></i></div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="service_name" label="服务负责人"></el-table-column>
                <el-table-column label="注册/开通邀请">
                    <template slot-scope="scope">
                        <div>
                            <div>{{scope.row.superior_name}}</div>
                            <div>{{scope.row.superior_name}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="培训反馈分" align="center" width="150">
                    <template slot-scope="scope">
                        <div class="fb-wrap">
                            <div @click="clickFeedback(scope.row)" class="btn" v-if="scope.row.service_send_status == 0">发送反馈</div>
                            <div v-if="scope.row.service_send_status == 1">
                                <div class="feedback" v-if="scope.row.service_feedback_status == 0">
                                    <div>已发送/待反馈</div>
                                    <div>{{scope.row.service_send_time}}</div>
                                </div>
                                <div class="score" v-if="scope.row.service_feedback_status == 1">{{scope.row.service_feedback_score}}</div>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="100">
                    <template slot-scope="scope">
                        <div class="btn-wrap">
                            <div class="btn" @click="clickEdit(scope.row)">编辑行业</div>
                            <div class="btn" @click="clickSupplier(scope.row)">供应商</div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-wrap">
            <div class="pagination-wrap">
                <el-pagination
                    @current-change="handleCurrentChange"
                    @size-change="handleSizeChange"
                    :current-page.sync="currentPage"
                    :page-sizes="[20, 50, 100, 200]"
                    :page-size="page_size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <!-- 发送反馈 -->
        <el-dialog
            :visible.sync="isShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px">
            <div class="feedback-wrap">
                <div class="fw-title">选择要发送反馈的用户</div>
                <div class="fw-list">
                    <el-table border="" :data="feedbackList" style="width: 100%">
                        <el-table-column align="center" prop="name" label="商家名称"></el-table-column>
                        <el-table-column align="center" prop="phone" label="联系方式"></el-table-column>
                        <el-table-column align="center">
                            <template slot-scope="scope">
                                <div class="btn" @click="clickSubmit(scope.row)">发送</div>
                            </template>
                        </el-table-column>
                   
                    </el-table>
                </div>
            </div>
        </el-dialog>
        <!-- 编辑行业 -->
        <el-dialog
            :visible.sync="isEditShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="500px">
            <div class="class-wrap">
                <div class="cw-title">编辑行业信息</div>
                <div class="cw-list">
                    <div class="item">
                        <div class="tit">商家经营者名称</div>
                        <el-input v-model="info.operator_name" placeholder="请输入商家经营者名称"></el-input>
                    </div>
                    <div class="item">
                        <div class="tit">商家所在省份</div>
                        <el-select v-model="info.province" placeholder="请选择省份" @change="bindProvinceChange">
                            <el-option v-for="(item,index) in provinceList" :label="item.label" 
                                :value="item.value+'|'+item.label" :key="index">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <div class="tit">商家所在城市</div>
                        <el-select v-model="info.city" placeholder="请选择城市" @change="bindCityChange">
                            <el-option v-for="(item,index) in cityList" :label="item.label" 
                                :value="item.value+'|'+item.label" :key="index">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <div class="tit">商家所在区/县</div>
                        <el-select v-model="info.district" placeholder="请选择区/县" @change="bindCountyChange">
                            <el-option v-for="(item,index) in countyList" :label="item.label" 
                                :value="item.value+'|'+item.label" :key="index">
                            </el-option>
                        </el-select>
                    </div>
                    <div class="select">
                        <div class="tit">选择商家从事行业</div>
                        <div class="btn" @click="clickSelect">选择</div>
                    </div>
                    <div class="select-list">
                        <div class="list" v-for="(item,index) in industrySelectList" :key="index">
                            <div>{{item.name}}</div>
                            <div @click="clickDelSelect(index)" class="del"><i class="el-icon-remove"></i></div>
                        </div>
                    </div>
                </div>
                <div class="cw-btn" @click="clickAddMain">确定</div>
            </div>
        </el-dialog>
        <!-- 选择行业 -->
        <div class="select-box">
            <el-dialog
                :visible.sync="isSelectShow"
                :close-on-click-modal="false"
                :show-close="false"
                :close-on-press-escape="false"
                width="780px">
                <div class="selcet-wrap">
                    <div class="sw-title">选择行业</div>
                    <div class="sw-list">
                        <div class="list-box">
                            <div class="list"  :class="item.isSelect? 'current':''" 
                                @click="clickSelectItem(index)" 
                                v-for="(item,index) in industryList" :key="index">
                                <div>{{item.name}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="sw-btn">
                        <div class="btn cancel" @click="clickSelectCancel">取消</div>
                        <div class="btn" @click="clickSelectSubmit">确定</div>
                    </div>
                </div>
            </el-dialog>
        </div>
        <!-- 关联供应商 -->
        <el-dialog
            :visible.sync="isRelevanceShow"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            width="700px">
            <div class="relevanc-wrap">
                <div class="rw-title">
                    <div class="title">关联供应商</div>
                </div>
                <div class="rw-list">
                    <div class="list-box">
                        <div class="l-title">
                            <div class="title">供应商名称</div>
                            <div class="title">绑定状态</div>
                            <div class="title">设为主供应商</div>
                        </div>
                        <div class="list" v-for="(item,index) in supplierList" :key="index">
                            <div class="item l-left">
                                <div class="title">{{item.name}}</div>
                            </div>
                            <div class="item">
                                <div @click="clickCancelBind(item)" class="l-right n-btn" v-if="item.bind == 1">已绑定</div>
                                <div @click="clickBind(item)" class="l-right" v-else>绑定</div>
                            </div>
                            <div class="item">
                                <el-switch
                                    @change="changeIsChange(item)"
                                    v-model="item.isSelect"
                                    active-color="#0068FF"
                                    inactive-color="#c0c0c0">
                                </el-switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <!-- 提示 -->
        <el-dialog
            :visible.sync="isDel"
            width="500px"
            :show-close="false">
            <div class="channel-wrap">
                <div class="cw-title">{{delName}}</div>
                <div class="cw-btn">
                    <div class="btn cancel" @click="clickCancel">取消</div>
                    <div class="btn confirm" @click="clickConfirm">确定</div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import api from "@/utils/common.js"
import axios from "axios";
import pageMixin from '@/internal/pageMixin'
const datas = require('@/utils/area-data.js');
import commonSelect from "@/components/commonSelect.vue"
import {getStartEndByPeriod} from "@/utils/timeCycle.js"
export default {
    components:{
        commonSelect
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'userServiceList',
            loading: false,
            keyword: '',
            currentPage: 1,
            page_size: 20,
            total: 0,
            start_date: '',
            end_date: '',
            tableData: [],
            serviceOptions: [],
            serviceValue: '',
            userOptions: [],
            userValue: '',
            sourceOptions: [{
                id: '',
                name: '全部'
            },{
                id: 1,
                name: '小程序'
            },{
                id: 2,
                name: '桌面端'
            },{
                id: 3,
                name: '网页端'
            }],
            feedbackOptions: [{
                id: '',
                name: '全部'
            },{
                id: 1,
                name: '培训未发反馈'
            },{
                id: 2,
                name: '培训未反馈'
            },{
                id: 3,
                name: '培训已反馈'
            }],
            feedbackValue: '',
            editOptions: [{
                id: '',
                name: '全部'
            },{
                id: 0,
                name: '未标记行业'
            },{
                id: 1,
                name: '已标记行业'
            }],
            editValue: '',
            isShow: false,
            feedbackList: [],
            height: '',
            isEditShow: false,
            info: {
                uid: '',
                operator_name: '',
                province: '',
                city: '',
                district: '',
            },
            provinceList: [],
            cityList: [],
            countyList: [],
            isSelectShow: false,
            industryList: [],
            selectList: [],
            industrySelectList: [],
            isRelevanceShow: false,
            supplierList: [],
            isDel: false,
            shopName: '',
            delName: '',
            uid: '',
            factory_id: '',
            mainFactory: '',
            source: '',
            selectGoods: [],
            // isBatchHang: false,
            // isBatchPhang: false,
            // isBatchSelect: false,
            // batchInfo: {
            //     operator_name: '',
            //     province: '',
            //     city: '',
            //     district: '',
            // },
            bloading: false
        }
    },
    mounted () {
        this.getActivatePackage()
        this.getServiceWhole()
        this.getUserServiceList();
        this.getHeight()
        this.provinceList = datas.areaList.provinceList;
        this.getIndustryWhole()
    },
    methods: {
        // 批量导入商家
        clickUpdataShop(file){
            let token = localStorage.getItem("token");
            var authorization = 'Bearer ' + token
            let formData = new FormData();
            formData.append('file',file.file);
            this.bloading = true;
            let url = 'https://ceshi.ukoocloud.com/service/system/user_import'
            axios({
                url: url,
                data: formData,
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data', 
                    'Authorization': authorization,
                }
            }).then((res)=> {
                if(res.data.code == 200){
                    this.$message.success(res.data.msg);
                    this.getUserServiceList();
                }else{
                    this.$message.error(res.data.msg);
                }
                this.$refs.upload.clearFiles()
                this.bloading = false;
            })
        },
        // 取消选择
        clickDeselect(){
            this.selectGoods = []
            this.$refs.multipleTableRef.clearSelection()
        },
        // 多选
        handleSelectionChange(val){
            this.selectGoods = val
        },
        // 确定操作
        changeIsChange(item){
            const uid = this.selectGoods.map(item => item.id);
            let params = {
                uid: uid,
                factory_id: item.id,
            }
            api.post('/service/service/set_user_service_main_supplier', params, (res) =>  {
                this.$message.success('操作成功')
                this.getUserServiceList();
                this.getSupplierList()
            });
        },
        // 取消
        clickCancel(){
            this.isDel = false;
            // this.isRelevanceShow = true
            // this.getSupplierList()
        },
        // 确定操作
        clickConfirm(){
            const uid = this.selectGoods.map(item => item.id);
            let params = {
                uid: uid,
                factory_id: this.factory_id,
            }
            api.post('/service/service/user_service_supplier_edit', params, (res) =>  {
                this.$message.success('操作成功')
                this.isDel = false
                this.isRelevanceShow = true
                if(this.uid == ''){
                    this.isRelevanceShow = false
                }else{
                    this.getUserServiceList();
                    this.getSupplierList()
                }
            });
        },
        // 绑定
        clickBind(item){
            this.isDel = true;
            this.factory_id = item.id
            if(this.uid == ''){
                this.delName = '确定绑定该供应商?'
            }else{
                this.delName = '确定为商家【'+this.shopName+'】绑定供应商【'+item.name+'】?'
            }
        },
        // 取消绑定
        clickCancelBind(item){
            this.isDel = true;
            this.factory_id = item.id
            if(this.uid == ''){
                this.delName = '确定解除绑定该供应商?'
            }else{
                this.delName = '确定解除商家【'+this.shopName+'】绑定供应商【'+item.name+'】?'
            }
            
        },
        clickBatchSupplier(){
            this.uid = ''
            this.isRelevanceShow = true
            this.getSupplierList()
        },
        // 供应商
        clickSupplier(row){
            this.selectGoods = []
            this.selectGoods.push(row)
            this.isRelevanceShow = true
            this.shopName = row.name
            this.uid = row.id
            this.getSupplierList()
        },
        getSupplierList(){
            let params = {
                uid: this.uid
            }
            api.get('/service/service/user_service_supplier', params, (res) =>  {
                res.data.list.forEach(v =>{
                    if(v.id == res.data.main_factory_id){
                        v.isSelect = true
                    }else{
                        v.isSelect = false
                    }
                })
                this.supplierList = res.data.list
            });
        },
        // 编辑行业
        clickAddMain(){
            const uid = this.selectGoods.map(item => item.id);
            const industry_id = this.industrySelectList.map(item => item.id);
            let params = {
                uid: uid,
                operator_name: this.info.operator_name,
                industry_id: industry_id,
                province: this.info.province,
                city: this.info.city,
                district: this.info.district,
            }
            api.post('/service/service/user_service_edit', params, (res) =>  {
                this.$message.success('编辑成功')
                this.getUserServiceList();
                this.isEditShow = false
            });
        },
        // 删除选择行业
        clickDelSelect(index){
            this.industrySelectList.splice(index,1)
        },
        // 选择行业
        clickSelectItem(index) {
            let list = [];
            this.$nextTick(() => {
                this.industryList[index].isSelect = !this.industryList[index].isSelect
                this.industryList.forEach((v,i) => {
                    if(v.isSelect === true){
                        list.push(v)
                    }
                });
                this.selectList = list
            });
        },
        // 确定选择
        clickSelectSubmit(){
            this.isSelectShow = false;
            this.industrySelectList = this.selectList;
        },
        // 选择取消
        clickSelectCancel(){
            this.industryList.forEach((v,i) =>{
                v.isSelect = false
            })
            this.selectList = []
            this.isSelectShow = false
        },
        // 点击选择行业
        clickSelect(){
            this.selectList = this.industrySelectList
            this.industryList.forEach((v,i) =>{
                this.selectList.forEach((v2,i2)=>{
                    if(v.id == v2.id){
                        v.isSelect = true
                    }else{
                        v.isSelect = false
                    }
                })
            })
            this.isSelectShow = true
        },
        // 获取行业列表
        getIndustryWhole(){
            api.get('/service/service/get_industry_whole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                    isSelect: false
                }
                })
                this.industryList = resultSecond
            });
        },
        // 编辑行业
        clickEdit(row){
            this.selectGoods = []
            this.selectGoods.push(row)
            this.isEditShow = true
            this.industrySelectList = row.industry
            this.info.uid = row.id
            if(row.operator_name){
                this.info.operator_name = row.operator_name
            }else{
                this.info.operator_name = ''
            }
            if(row.province){
                this.info.province = row.province
            }else{
                this.info.province = ''
            }
            if(row.city){
                this.info.city = row.city
            }else{
                this.info.city = ''
            }
            if(row.district){
                this.info.district = row.district
            }else{
                this.info.district = ''
            }
        },
        // 省
        bindProvinceChange(vals) {
            // 拿到省份对应的唯一编号
            let arr = vals.split('|')
            this.info.province = arr[1]
            this.info.city = '';
            this.info.district = '';
            // 拿到对应的城市
            this.cityList = this.addrInit(2, datas.areaList.cityList, arr[0]);
        },
        // 市
        bindCityChange(vals) {
            this.info.district = '';
            let arr = vals.split('|')
            this.info.city = arr[1]
            // 拿到对应的区县
            this.countyList = this.addrInit(4, datas.areaList.countyList, arr[0]);
        },
        // 区县
        bindCountyChange(vals) {
            let arr = vals.split('|')
            this.info.district = arr[1]
        },
        // 将对象转为数组
        transArray(obj) {
            let arr = [];
            for (let i in obj) {
            arr.push(obj[i]);
            }
            return arr;
        },
        /**
         * 封装方法-获取相对应的省市区
         * @param {number} num 截取的位数
         * @param {*} list 要查询的数组
         * @param {*} str 要截取的字符串
         * @returns
         */
        addrInit(num, list, str) {
            let strSub = str.substr(0, num);
            let arr = this.transArray(list);
            let newArr = arr.filter(function (val, index, arr) {
            let newStr = val.value.substr(0, num);
            return newStr == strSub;
            });
            return newArr;
        },
        // 获取高度
        getHeight() {
            const height = this.$refs.myElement.clientHeight + 200;
            this.height = height + 'px'
        },
        // 发送反馈
        clickSubmit(row){
            let params = {
                uid: row.uid,
                tid: row.tid
            }
            api.post('/service/service/user_service_send', params, (res) =>  {
                this.$message.success('已发送')
                this.getUserServiceList();
                this.isShow = false
            });
        },
        clickFeedback(row){
            this.feedbackList = row.user_team_list;
            this.isShow = true
        },
        // 筛选
        changeSelect(){
            this.keyword = '';
            this.currentPage = 1
            this.getUserServiceList();
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1
            this.getUserServiceList();
        },
        // 获取用户服务列表
        getUserServiceList(){
            this.loading = true
            let params = {
                page_num: this.currentPage,
                page_size: this.page_size,
                end_date: this.end_date,
                start_date: this.start_date,
                info: this.keyword,
                service_id: this.serviceValue,
                activate_package_id: this.userValue,
                service_feedback_status: this.feedbackValue,
                industry_status: this.editValue,
                source: this.source,
            }
            api.get('/service/service/get_user_service_list', params, (res) =>  {
                this.tableData = res.data.list;
                this.total = res.data.count
                this.loading = false
            });
            setTimeout(() => {
                this.loading = false
            }, 2000);
        },
        // 获取全部套餐
        getActivatePackage(){
            api.get('/service/service/get_activate_package_hole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                }
                })
                let quan = {
                    id: '',
                    name: '全部',
                }
                resultSecond.unshift(quan);
                this.userOptions = resultSecond
            });
        },
        // 获取服务负责人
        getServiceWhole(){
            api.get('/service/service/get_service_whole', {}, (res) =>  {
                let resultSecond =  res.data.list.map(v =>{
                return{
                    id: v.id,
                    name: v.name,
                }
                })
                let quan = {
                    id: '',
                    name: '全部',
                }
                resultSecond.unshift(quan);
                this.serviceOptions = resultSecond
            });
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getUserServiceList();
        },
        handleSizeChange(val){
            this.page_size = val;
            this.getUserServiceList();
        },
        // 日期选择
        changeDate(startDate,endDate){
            console.log(startDate,endDate);
            this.start_date = startDate.slice(0,11)+' 00:00:01';
            this.end_date = endDate.slice(0,11)+' 23:59:59';
            this.getUserServiceList();
        },
        clickTagDate(item){
            console.log(item);
            if(item.label != 'date'){
                let date = getStartEndByPeriod(item.name)
                this.start_date = date.startDate;
                this.end_date = date.endDate;
                this.getUserServiceList();
            }
        },
    },
}
</script>

<style lang='scss'>
.userServiceList{
    height: calc(100vh - 60px);
    overflow: hidden;
    .ud-title{
        position: sticky;
        top: 0;
        left: 0;
    }
    .ud-title-box{
        padding: 15px 40px 0 40px;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid #ddd;
        .screen-left{
            padding-bottom: 15px;
            margin-right: 30px;
        }
        .sr-item{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-right: 30px;
            padding-bottom: 15px;
            .i-title{
                font-size: 16px;
                color: #0a032d;
                padding-right: 20px;
            }
            .el-select{
                width: 150px;
            }
        }
    }
    .ud-title-screen{
        background: #fff;
        padding: 10px 40px;
        .c-search{
            width: 400px;
            height: 40px;
            border-radius: 6px;
            border: 1px solid #ddd;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 0 10px;
            .el-input{
                width: calc(100% - 20px);
                .el-input__inner{
                    border: none;
                    outline: none;
                    padding: 10px ;
                }
            }
        }
        .c-btn{
            width: 100px;
            height: 40px;
            border-radius: 6px;
            background: #0068ff;
            border: 1px solid #0068ff;
            color: #fff;
            font-size: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
        }
        .s-btn{
            height: 40px;
            padding: 0 20px;
            border-radius: 6px;
            font-size: 14px;
            color: #fff;
            cursor: pointer;
            background: #0068ff;
            margin-right: 20px;
        }
        .t-t-btn{
            .select{
                font-size: 14px;
                color: #000;
                margin-right: 20px;
            }
            .cancel{
                font-size: 14px;
                color: #0068ff;
                cursor: pointer;
                padding: 0 10px;
            }
        }
        .t-btn{
            height: 40px;
            padding: 0 20px;
            background: #f7f7f7;
            border-radius: 6px;
            font-size: 14px;
            color: #969696;
            cursor: pointer;
        }
    }
    .ss-content{
        margin: 20px;
        background: #fff;
        padding: 20px;
        border-radius: 8px;
        .code{
            text-align: center;
            width: 150px;
            padding: 5px 20px;
            border: 1px solid #ddd;
            background: #e6effd;
            border-radius: 9px;
        }
        .btn-wrap{
            .btn{
                cursor: pointer;
                font-size: 14px;
                color: #0068ff;
            }
        }
        .is-pro-wrap{
            .btn{
                width: 70px;
                height: 50px;
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                color: #0550be;
                background: #EBF3FF;
                line-height: 18px;
            }
            .pro{
                color: #0550be;
                background: #ebf3ff;
            }
        }
        .fb-wrap{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 90px;
                height: 32px;
                border-radius: 6px;
                font-size: 14px;
                color: #fff;
                background: #6ba8ff;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
            }
            .score{
                font-size: 20px;
                color: #101010;
                font-weight: bold;
                text-align: center;
            }
            .feedback{
                font-size: 12px;
                color: #949494;
                text-align: center;
            }
        }
    }
    .page-wrap{
        width: 100%;
        z-index: 999;
        background: #fff;
        height: 60px;
        position: fixed;
        bottom: 0px;
        left: 50%;
        transform: translate(-50%);
        display: flex;
        justify-content: center;
        align-items: center;
        .pagination-wrap{
            margin-left: 250px;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
    .feedback-wrap{
        .fw-title{
            font-size: 16px;
            color: #101010;
            padding-left: 20px;
        }
        .fw-list{
            padding: 20px 20px;
            .btn{
                font-size: 14px;
                color: #0068ff;
                cursor: pointer;
            }
        }
    }
    .class-wrap{
        .cw-title{
            font-size: 16px;
            color: #101010;
            padding-left: 20px;
        }
        .cw-list{
            padding: 20px;
            .item{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 15px;
                .tit{
                    text-align: right;
                    font-size: 15px;
                    color: #101010;
                    width: 150px;
                    padding-right: 20px;
                }
                .el-select{
                    width: 460px;
                }
            }
            .select{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;
                .tit{
                    font-size: 15px;
                    color: #101010;
                    width: 150px;
                    padding-right: 20px;
                }
                .btn{
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 70px;
                    height: 28px;
                    border-radius: 6px;
                    color: #0068ff;
                    border: 1px solid #0068ff;
                }
            }
            .select-list{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 20px 0;
                .list{
                    padding: 5px 20px;
                    background: #eff3f9;
                    color: #101010;
                    font-size: 14px;
                    position: relative;
                    margin-right: 15px;
                    margin-bottom: 10px;
                    .del{
                        cursor: pointer;
                        position: absolute;
                        top: -8px;
                        right: -8px;
                        .el-icon-remove{
                            color: #0068ff;
                            font-size: 22px;
                        }
                    }
                }
            }
        }
        .cw-btn{
            width: 100px;
            height: 40px;
            border-radius: 4px;
            font-size: 15px;
            color: #fff;
            background: #0068ff;
            margin: 0 auto;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    .select-box{
        .el-dialog__header{
            padding: 0 !important;
        }
    }
    .selcet-wrap{
        .sw-title{
            font-size: 18px;
            color: #101010;
            border-bottom: 1px solid #ddd;
            padding: 15px 30px;
        }
        .sw-list{
            height: 400px;
            overflow-y: auto;
            margin-top: 20px;
            .list-box{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding: 0 30px;
                .list{
                    width: 120px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #eff3f9;
                    border-radius: 6px;
                    font-size: 14px;
                    color: #101010;
                    margin-right: 20px;
                    margin-bottom: 20px;
                    cursor: pointer;
                }
                .current{
                    background: #0068ff;
                    color: #fff;
                }
            }
        }
        .sw-btn{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0;
            .btn{
                width: 100px;
                height: 34px;
                border-radius: 6px;
                border: 1px solid #0068ff;
                background: #0068ff;
                font-size: 14px;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0 20px;
                cursor: pointer;
            }
            .cancel{
                background: #fff;
                color: #0068ff;
            }
        }
    }
    .relevanc-wrap{
        .rw-title{
            padding: 0 30px 20px 30px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .title{
                font-size: 20px;
                color: #101010;
            }
            .add{
                padding: 7px 10px;
                border-radius: 6px;
                background: #0068ff;
                color: #fff;
                font-size: 14px;
                margin-left: 30px;
                cursor: pointer;
            }
        }
        .rw-list{
            height: 400px;
            background: #f5f5f5;
            overflow-y: auto;
            .list-box{
                .l-title{
                    background: #fff;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 20px;
                    .title{
                        flex: 1;
                        font-size: 16px;
                        color: #101010;
                        text-align: center;
                    }
                }
                .list{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 15px 0px;
                    border-bottom: 1px solid #ddd;
                    .item{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex: 1;
                    }
                    .l-left{
                        .title{
                            font-size: 14px;
                            color: #101010;
                        }
                        .date{
                            font-size: 12px;
                            color: #9d9d9d;
                            padding-top: 5px;
                        }
                    }
                    .l-right{
                        width: 80px;
                        height: 25px;
                        border: 1px solid #0068ff;
                        color: #0068ff;
                        border-radius: 6px;
                        cursor: pointer;
                        font-size: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                    .n-btn{
                        border-color: #b4b4b4;
                        color: #b4b4b4;
                    }
                }
            }
        }
    }
    .channel-wrap{
        padding: 0 80px;
        .cw-title{
            font-size: 15px;
            color: #101010;
            text-align: center;
            padding: 15px 0;
        }
        .cw-btn{
            display: flex;
            justify-content: center;
            align-items: center;
            .btn{
                width: 100px;
                height: 35px;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 30px 10px;
                border: 1px solid #0068ff;
                font-size: 15;
                color: #0068ff;
                cursor: pointer;
            }
            .confirm{
                background: #0068ff;
                color: #fff;
            }
        }
    }
}
.batch-btn-box{
    .batch{
        cursor: pointer;
        padding: 5px 0;
        text-align: center;
        font-size: 14px;
        color: #333;
    }
    .batch:hover{
        background: #0068ff;
        color: #fff;
        border-radius: 4px;
    }
}
</style>
